<template>
<div>
    <h1 class='text-center'>404 Not Found!</h1>
    <p class='text-center'>Page You are looking for cannot be found</p>
</div>
</template>
<script>
export default {
    
}
</script>